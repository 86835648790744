.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.jodit-container .jodit-workplace{
  min-height: 1px;
    min-width: 200px;
    max-width: 100%;
    max-height: 60vh;
    height: auto;
    overflow: scroll;
    width: auto;
}

.ant-form-item {
  margin-bottom: 0px;
}