.trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 5px;
    cursor: pointer;
    transition: color 0.3s;
}

.trigger:hover {
    color: #1890ff;
}

.logo {
    width: 85%;
    margin: 7%;
    height: 40px;
    background: white;
    display: flex;
    align-items: center;
    border-radius: 2px;
}

.user-menu {
    position: inherit;
    right: 0px;
    top: 5px;
    height: 60px;
    float: right;
    border: none;
}

.ant-layout-content > .content {
    margin: 10px;
    background: #fff;
    padding: 10px;
}

.content.profile .ant-card-head{
    height: 43px;
    min-height: 43px;
}

.content.profile .ant-pagination.ant-table-pagination{
    float: left;
}
.content.profile .ant-card-body{
    padding: 7px;
}